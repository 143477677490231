import {ElView} from "../../shared/el-view";
import {Translator} from "../../shared/translation/translator";
import {Router} from "../../shared/routing/router";
import {capitalizeFirstLetter} from "../../shared/helper/string-helper";
import {getCurrentDateDDMMYYYY} from "../../shared/helper/date-helper";
import {DownloadFile} from "../../shared/component/download-file";

export const AdminUsersPage = ElView.extend({
    el: '.j-admin-users',
    events: {
        'click .j-activate-user': 'onActivateUserButtonClick',
        'click .j-download-csv': 'onDownloadCSV',
    },
    datatable: null,
    init: function () {
        this.find('.j-select-2-user-role').select2({
            minimumResultsForSearch: -1,
        });

        this.initUsersTable();
    },

    initUsersTable() {
        const _this = this;
        this.datatable = this.find('#admin-users-datatable').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: Router.generate('users-table-data'),
                    pageSize: 10,
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                                // info: Translator.trans('displaying')
                            },
                        },
                    },
                },
                rows: {
                    autoHide: false,
                },

                layout: {
                    scroll: true,
                    scrollX: true,
                },

                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#users-search'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'registeredAt',
                        title: capitalizeFirstLetter('Fecha de Registro'),
                        template: (row) => {
                            return `<div class="d-flex font-weight-bold text-muted" data-toggle="tooltip" data-placement="top" title="${row.registeredAt}">${row.registeredAt}</div>`;
                        }
                    },
                    {
                        field: 'name',
                        title: capitalizeFirstLetter(Translator.trans('fullname')),
                        template: (row) => {
                            return `
                                <div class="d-flex font-weight-bold">
                                        ${capitalizeFirstLetter(row.name)} ${capitalizeFirstLetter(row.surname)}
                                </div>`;

                        }
                    },
                    {
                        field: 'username',
                        title: capitalizeFirstLetter(Translator.trans('username')),
                        template: (row) => {
                            return `<div class="d-flex font-weight-bolder">${row.username}</div>`;

                        }
                    },
                    {
                        field: 'email',
                        title: capitalizeFirstLetter('email'),
                        template: (row) => {
                            return `<div class="d-flex font-weight-bold text-muted" data-toggle="tooltip" data-placement="top" title="${row.email}">${row.email}</div>`;
                        }
                    },
                    {
                        field: 'roomsName',
                        title: capitalizeFirstLetter('Salas con credenciales'),
                        class: 'd-none',
                        textAlign: 'center',
                        template: (row) => {
                            let html = '';

                            for (let index in row.roomsName)
                                html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.roomsName[index]}</span>`

                            return html;
                        }
                    },
                    {
                        field: 'nicksAndLogins',
                        title: capitalizeFirstLetter('nicksAndLogins'),
                        class: 'd-none',
                        textAlign: 'center',
                        template: (row) => {
                            let html = '';

                            for (let index in row.nicksAndLogins)
                                html += `<span class="label label-inline label-rounded font-weight-bold label-light-info m-1">${row.nicksAndLogins[index]}</span>`

                            return html;
                        }
                    },
                    {
                        field: 'habcoins',
                        title: 'Habcoins',
                        width: 100,
                        template: (row) => {
                            return `<a  href="${_this.route('admin-wallet-movements', {'walletId': row.walletId})}" class="font-weight-bolder">${row.habcoins.toFixed(0)}</a>`;
                        }
                    },
                    {
                        field: 'appName',
                        title: capitalizeFirstLetter('Web'),
                        template: (row) => {
                            return `<div class="d-flex font-weight-bold text-muted" data-toggle="tooltip" data-placement="top" title="${row.appName}">${row.appName}</div>`;
                        }
                    },
                    {
                        field: 'higherRole',
                        title: capitalizeFirstLetter(Translator.trans('user.roles')),
                        textAlign: 'center',
                        width: 120,
                        template: (row) => {
                            const roleToCssClassMap = {
                                'ROLE_USER': 'primary',
                                'ROLE_SUPERADMIN': 'danger',
                                'ROLE_SUPPORT': 'warning',
                                'ROLE_ADMIN': 'danger',
                                'ROLE_SALES': 'info',
                            };

                            if (row.higherRole.length > 0)
                                return `<span class="label label-inline label-rounded font-weight-bold label-light-${roleToCssClassMap[row.higherRole]}">${capitalizeFirstLetter(
                                    Translator.trans('user.role.' + row.higherRole))}</span>`;
                            else
                                return `<span class="label label-inline label-rounded font-weight-bold label-light-">${capitalizeFirstLetter(
                                    Translator.trans('disabled'))}</span>`;
                        }
                    },
                    {
                        field: 'status',
                        title: capitalizeFirstLetter(Translator.trans('status')),
                        textAlign: 'center',
                        template: (row) => {
                            if (row.isActive)
                                return `<span class="label label-inline label-rounded font-weight-bold label-light-primary">Activo</span>`;
                            else
                                return `<span class="label label-inline label-rounded font-weight-bold label-light-danger">Inactivo</span>`;
                        }
                    },
                    {
                        field: 'referrerName',
                        title: capitalizeFirstLetter('Referido por'),
                        width: 120,
                        template: (row) => {
                            return `<div class="d-flex font-weight-bold text-muted">${row.referrerName}</div>`;

                        }
                    },
                    {
                        field: 'salesName',
                        width: 120,
                        title: capitalizeFirstLetter('Propietario'),
                        template: (row) => {
                            return `<div class="d-flex font-weight-bold text-muted">${row.salesName}</div>`;

                        }
                    },
                    {
                        field: 'Actions',
                        sortable: false,
                        title: capitalizeFirstLetter(Translator.trans('datatable.actions')),
                        textAlign: 'center',
                        template: function (row) {
                            const editRoute = Router.generate('admin-user-details', {'userId': row.id});

                            if (row.isActive)
                                return `<a href="${editRoute}" class="btn btn-sm btn-icon btn-light-warning mr-2" title="Edit details">
                                          <span class="fas fa-pencil-alt">
                                          </span>
                                    </a>`;
                            else {
                                return `<div class="btn btn-sm btn-icon btn-light-primary mr-2 j-activate-user" title="Activate" data-user-id="${row.id}">
                                          <span class="fa fa-check">
                                          </span>
                                        </div>
                                        <a href="${editRoute}" class="btn btn-sm btn-icon btn-light-warning mr-2" title="Edit details">
                                          <span class="fas fa-pencil-alt">
                                          </span>
                                        </a>`;
                            }

                        },

                    },

                ],
            }
        );

        $('#users-search-rol').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'higherRole');
        });
        $('#user-search-rooms').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'roomsName');
        });
        $('#user-search-nicks-logins').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'nicksAndLogins');
        });
        $('#user-search-apps').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'appName');
        });
    },

    async onActivateUserButtonClick(event) {
        const userId = event.currentTarget.getAttribute('data-user-id');

        const confirmation = await this.confirmation('¿Seguro que desea activar el usuario?', '', 'Confimar', 'Cancelar');

        if (!confirmation.isDismissed) {
            try {
                await $.post('activate-user-manually', {'userId': userId});
                toastr.success('Usuario activado');
                this.datatable.reload();
            } catch (error) {
                this.confirmationError('Error', 'Ha ocurrido algún error');
            }
        }
    },

    onDownloadCSV() {
        let csvContent = 'Fecha de Registro;FullName;Username;Email;Salas con credenciales;Habcoins;Web;Role;Estado;Referido por;Propietario\r\n';

        this.find('#admin-users-datatable').find('tbody tr').each((index, rowElement) => {
            const $rowElement = $(rowElement);
            const registeredAt = $rowElement.find('[data-field="registeredAt"]').text().trim();
            const fullName = $rowElement.find('[data-field="name"]').text().trim();
            const username = $rowElement.find('[data-field="username"]').text().trim();
            const email = $rowElement.find('[data-field="email"]').text().trim();
            const roomsName = $rowElement.find('[data-field="roomsName"]').text().trim();
            const habcoins = $rowElement.find('[data-field="habcoins"]').text().trim();
            const appName = $rowElement.find('[data-field="appName"]').text().trim();
            const role =  $rowElement.find('[data-field="higherRole"]').text().trim();
            const status = $rowElement.find('[data-field="status"]').text().trim();
            const referrer = $rowElement.find('[data-field="referrerName"]').text().trim();
            const owner = $rowElement.find('[data-field="salesName"]').text().trim();

            let row = registeredAt + ';' + fullName + ';' + username + ';' + email + ';' + roomsName + ';' + habcoins +
                ';' + appName + ';' + role + ';' + status + ';' + referrer + ';' + owner;
            csvContent += row + '\r\n';
        })

        const today = getCurrentDateDDMMYYYY('-');

        const fileDownloader = new DownloadFile({
            el: this,
            type: 'text/csv;charset=utf-8;',
            content: csvContent,
            filename: 'admin_user_details_' + today + '.csv'
        });
    }
});