import {ElView} from "../../../shared/el-view";
import {Router} from "../../../shared/routing/router";
import {capitalizeFirstLetter} from "../../../shared/helper/string-helper";
import {Translator} from "../../../shared/translation/translator";
import uuid4 from "uuid4/browser.mjs";

export const AdminUserCredentialsManagement = ElView.extend({
    el: '.j-admin-user-credentials-management',
    events: {
        'click .j-credentials-edit-button': 'onClickAddOrUpdateUserRoomCredentials',
        'click .j-update-user-credentials-modal-save': 'onClickUpdateUserCredentialsModalSave',
        'hidden.bs.modal #update-user-credentials-modal': 'onClickUpdateUserCredentialsModalClose',
        'click .j-credentials-remove-button': 'onClickRemoveUserCredentials',
        'click .j-credentials-validate-button': 'onClickValidateCredentials',
    },
    users: null,
    rooms: null,
    affiliates: null,
    datatable: null,

    init: function () {
        this.find('.j-select-2-credential-status').select2({
            minimumResultsForSearch: -1,
        })

        const route = Router.generate('core-api-admin-user-room-credentials')
        $.get(route)
            .done((response) => {
                this.initUserCredentialsTable(JSON.parse(response));
            })
    },

    initUserCredentialsTable() {
        this.datatable = this.find('#admin-user-credentials-table').KTDatatable(
            {
                data: {
                    type: 'remote',
                    source: this.route('core-api-admin-user-room-credentials'),
                    pageSize: 10,
                },
                translate: {
                    toolbar: {
                        pagination: {
                            items: {
                                info: Translator.trans('Displaying') + ' {{start}} - {{end}} ' + Translator.trans('of') + ' {{total}}'
                            },
                        },
                    },
                },
                pageSize: 10,
                rows: {
                    autoHide: false,
                },
                layout: {
                    scroll: false,
                },
                sortable: true,
                pagination: true,
                search: {
                    input: this.find('#nick-search'),
                    key: 'generalSearch'
                },
                columns: [
                    {
                        field: 'createdAt',
                        title: 'Creación',
                        width: 100,
                        template: (row) => {
                            return `<span class="j-credentials-created-at text-muted">${new Intl.DateTimeFormat('es').format(Date.parse(row.createdAt))}</span>`;
                        }
                    },
                    {
                        field: 'nick',
                        title: capitalizeFirstLetter('nick'),
                        width: 100,
                        template: (row) => {
                            return `<span class="j-credentials-nick font-weight-bold">${row.nick}</span>`;
                        }
                    },
                    {
                        field: 'login',
                        title: capitalizeFirstLetter('login'),
                        width: 100,
                        template: (row) => {
                            return `<span class="j-credentials-login font-weight-bold">${row.login}</span>`;
                        }
                    },
                    {
                        field: 'code',
                        title: capitalizeFirstLetter('ID Number'),
                        width: 100,
                        template: (row) => {
                            return `<span class="j-credentials-code font-weight-bold">${row.code}</span>`;
                        }
                    },
                    {
                        field: 'username',
                        title: 'Usuario',
                        width: 100,
                        template: (row) => {
                            const editRoute = Router.generate('admin-user-details', {'userId': row.userId});

                            return `<a href="${editRoute}" class="j-credentials-username" data-user-id="${row.userId}" title="Edit user">
                                          ${row.username}
                                    </a>`
                        }
                    },
                    {
                        field: 'roomName',
                        title: 'Sala',
                        width: 100,
                        template: (row) => {
                            const detailsRoute = this.route('admin-room-details', {roomId: row.roomId});

                            return `<a href="${detailsRoute}" class="j-credentials-room" title="Edit room">
                                       ${row.roomName}
                                    </a>`;
                        }
                    },
                    {
                        field: 'affiliateName',
                        title: 'Afiliado',
                        width: 100,
                        orderable: true,
                        template: (row) => {
                            const editRoute = Router.generate('admin-affiliate-details', {'affiliateId': row.affiliateId});

                            return `<a href="${editRoute}" class="j-credentials-affiliate" title="Edit details">
                                        ${row.affiliateName}
                                    </a>`;
                        }
                    },
                    {
                        field: 'status',
                        title: 'Estado',
                        textAlign: 'center',
                        width: 100,
                        template: (row) => {
                            if (row.status === 'pending')
                                return `<button class="btn btn-icon btn-light-warning" data-toggle="tooltip" data-placement="top" 
                                            title="Pendiente" style="cursor: default;"><span class="far fa-clock" style="font-size: 1.5rem;"></span></button>`;
                            if (row.status === 'verified')
                                return `<button class="btn btn-icon btn-light-primary" data-toggle="tooltip" data-placement="top" 
                                            title="Verificado" style="cursor: default;"><span class="flaticon2-check-mark" style="font-size: 1.5rem;"></span></button>`;
                        }
                    },
                    {
                        field: 'action',
                        title: 'Acción',
                        textAlign: 'center',
                        width: 125,
                        sortable: false,
                        template: (row) => {
                            let rejectButton = `<button type="button" class="btn btn-sm btn-icon btn-light-danger j-credentials-remove-button" 
                                                    data-toggle="tooltip" data-placement="top" title="Eliminar" data-user-room-credentials-id="${row.id}">
                                                    <span class="flaticon2-delete"  style="font-size: 1.3rem;"></span>
                                                </button>`;
                            let verifyButton = `<button type="button" class="btn btn-sm btn-icon btn-light-primary j-credentials-validate-button" 
                                                    data-toggle="tooltip" data-placement="top" title="Verificar" data-user-room-credentials-id="${row.id}">
                                                    <i class="fas fa-check"  style="font-size: 1.3rem;"></i>
                                                </button>`;

                            if (row.status === 'verified')
                                verifyButton = '';

                            if ((row.username !== '' || row.affiliateName !== '') && row.status !== 'pending')
                                rejectButton = '';

                            return `${rejectButton}
                                    <button type="button" class="btn btn-sm btn-icon btn-light-warning j-credentials-edit-button" data-user-room-credentials-id="${row.id}" 
                                         data-toggle="modal" data-target="#update-user-credentials-modal">
                                         <i class="fas fa-pencil-alt"  style="font-size: 1.3rem;"></i>
                                    </button>
                                    ${verifyButton}`;
                        }
                    },
                ],
            }
        );

        this.datatable.sort('createdAt', 'desc');

        $('#user-credentials-search-status').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val().toLowerCase(), 'status');
        });

        $('#user-credentials-search-room').on('change', (event) => {
            this.datatable.search($(event.currentTarget).val(), 'roomId');
        });
    },

    onClickAddOrUpdateUserRoomCredentials(event) {
        let userRoomCredentialsId = $(event.currentTarget).attr('data-user-room-credentials-id');
        if (_.isEmpty(userRoomCredentialsId) || _.isNull(userRoomCredentialsId) || _.isUndefined(userRoomCredentialsId))
            userRoomCredentialsId = uuid4();

        const route = Router.generate('core-api-manage-user-room-credentials', {credentialsId: userRoomCredentialsId});

        $.get(route)
            .done((response) => {
                const $modalBody = $(this.el).find('#update-user-credentials-modal .modal-body');
                $modalBody.append(response);
                $modalBody.find('.j-user-room-credentials-form-user-id-input').select2();
                $modalBody.find('.j-user-room-credentials-form-affiliate-id-input').select2();
                $modalBody.find('.j-user-room-credentials-form-room-id-input').select2();
            })
            .fail(() => {
                toastr.options = {
                    positionClass: 'toast-bottom-left',

                    progressBar: true,
                    preventDuplicates: true,
                    closeButton: true,
                };
                toastr.error(capitalizeFirstLetter(Translator.trans('error')));
            })

    },

    onClickUpdateUserCredentialsModalClose(event) {
        const $modal = $(event.currentTarget).closest('#update-user-credentials-modal');
        $modal.find('.modal-body').empty();
    },

    onClickUpdateUserCredentialsModalSave(event) {
        const $modal = $(event.currentTarget).closest('#update-user-credentials-modal');

        const id = $modal.find('.j-user-room-credentials-form-id-input').val();
        const nick = $modal.find('.j-user-room-credentials-form-nick-input').val();
        const roomId = $modal.find('.j-user-room-credentials-form-room-id-input').val();
        if (nick === '' || roomId === '') {
            toastr.options = {
                positionClass: 'toast-bottom-left',

                progressBar: true,
                preventDuplicates: true,
                closeButton: true,
            };
            toastr.error(capitalizeFirstLetter(Translator.trans('Nick, sala y usuario requeridos')));
            return;
        }

        const route = Router.generate('core-api-manage-user-room-credentials', {credentialsId: id});
        $.post(route, $modal.find('.j-user-room-credentials-form').serialize())
            .done(() => {
                this.datatable.reload();
                toastr.success('Creenciales actualizadas');
            })
            .fail((response) => {
                if (response.status === 400)
                    toastr.error(response.responseText);
            })
    },

    onClickRemoveUserCredentials(event) {
        const _this = this;

        Swal.fire({
            title: '¿Eliminar credenciales de usuario?',
            text: 'No podrás deshacer esta acción',
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then(function (result) {
            if (result.value) {
                const credentialsId = $(event.currentTarget).attr('data-user-room-credentials-id');
                const route = Router.generate('core-api-remove-user-room-credentials')
                const data = {'userRoomCredentialsId': credentialsId};

                $.post(route, data)
                    .always(() => {
                        setTimeout(() => {
                            // location.reload();
                            _this.datatable.reload();
                            toastr.info('Afiliado rechazado');
                        }, 1000);
                    })
                    .done(() => {
                        Swal.fire(
                            'Eliminado',
                            'Credenciales de usuario eliminadas',
                            'success'
                        )
                    })
                    .fail((result) => {
                        Swal.fire(
                            'Error',
                            result.statusText,
                            "error"
                        )
                    })
            } else if (result.dismiss === "cancel") {
                Swal.fire(
                    'cancelado',
                    'No se ha eliminado nada',
                    "error"
                )
            }
        });
    },

    async onClickValidateCredentials(event) {
        const $clicked = $(event.currentTarget);
        const credentialsId = $clicked.attr('data-user-room-credentials-id');
        const route = this.route('core-api-verify-user-room-credentials', {credentialsId})

        try {
            await $.post(route);
            this.datatable.reload();
            toastr.success('Creenciales verificadas');
        } catch (exception) {
            toastr.error('Error');
        }
    }
});
